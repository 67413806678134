<template>
	<div class="page">
		<div class="w680">
			<topHeader></topHeader>

			<div class="top-col">今日需学习 <b>0/10</b></div>

			<!-- 卡片 -->
			<div class="word-panel">
				<!-- 单词 -->
				<div class="master">
					<span>crayon</span>
					<div class="box-showdow"></div>
				</div>
				<!-- 读音 -->
				<div class="linsten-block">
					<div class="linsten-btn">
						<img src="@/assets/static/linsten.png" mode="" />
					</div>
					<span>美 /'krelan/</span>
				</div>
				<!-- 解释 -->
				<div class="desc-item" v-for="(item, index) in wordTpye" :key="index">
					<span>{{ item.label }}</span>
					<span>{{ item.value }}</span>
				</div>
				<!-- 例图 -->
				<img class="word-img" src="@/assets/static/example.png" />

				<div class="mid-line"></div>

				<div class="s-tip">根据例句回忆单词含义</div>

				<!-- 例句 -->
				<div class="sentence" v-html="sentence"></div>

				<div class="look-desc" v-if="!isShowMean">点击查看释义。</div>
				<div class="mean" v-else>这是一句释义</div>

				<div class="two-btn">
					<div>不认识</div>
					<div>认识</div>
				</div>
			</div>

			<div class="btn-group">
				<img src="@/assets/static/cancel_word_btn.png" desc="消词" alt="" />
				<img src="@/assets/static/star_icon_checked.png" v-if="true" desc="已收藏" alt="" />
				<img src="@/assets/static/star_icon.png" v-else desc="未收藏" alt="" />
			</div>
		</div>

		<!-- 学完的弹窗 -->
		<el-dialog :show-close="false" width="539px" :visible.sync="learnFinish" :close-on-click-modal="false">
			<img src="@/assets/static/finger_great.png" alt="" />

			<div class="dialog-text1">已新学 20 个单词</div>
			<div class="dialog-text2">拼写单词可以强化记忆哦，请记得去拼写！</div>

			<div class="dialog-sub-btns">
				<div @click="learnFinish = false">放弃拼写</div>
				<div @click="toWordSpelling">开始拼写</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import topHeader from "@/components/topHeader/topHeader.vue";

	export default {
		components: {
			topHeader,
		},
		data() {
			return {
				learnFinish: true, // 是否学完了
				isShowMean: true, //是否显示释义
				sentence: `<span class="sentence-wrod-style">I need some children's <span class="sentence-wrod-style-block"><div class="sentence-key-wrod-style"></div><span class="sentence-key-wrod-text">crayons</span></span>.</span>`,
				wordTpye: [{
					label: "n.",
					value: "蜡笔;彩色铅笔(或粉笔)",
				}, ],
			};
		},
		methods: {
			goBack() {
				this.$router.back();
			},
			// 跳转单词拼写
			toWordSpelling() {
				this.$router.push({
					name: "wordSpelling"
				});
			},
		},
	};
</script>
<style>
	.sentence-wrod-style {
		font-size: 16px;
		color: #000;
		margin-top: 8px;
	}

	.sentence-key-wrod-text {
		position: relative;
		z-index: 9;
	}

	.sentence-wrod-style-block {
		position: relative;
	}

	.sentence-key-wrod-style {
		position: absolute;
		width: 100%;
		height: 10px;
		background: #fdf0cf;
		left: 3px;
		bottom: 2px;
		border-radius: 2px;
	}
</style>
<style lang="scss" scoped>
	.dialog-text1 {
		font-weight: bold;
		margin-top: 27px;
		color: #5c3704;
		font-size: 24px;
	}

	.dialog-text2 {
		margin-top: 16px;
		color: #666666;
		font-size: 20px;
	}

	.dialog-sub-btns {
		margin-top: 44px;
		display: flex;
		align-items: center;

		div {
			width: 146px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			color: #fff;
			cursor: pointer;
			transition: 0.3s all;

			&:hover {
				opacity: 0.8;
			}

			&:first-child {
				background: #67adff;
			}

			&:last-child {
				margin-left: 20px;
				background: #51d9a5;
			}
		}
	}

	/deep/ .el-dialog {
		background: unset !important;
	}

	/deep/ .el-dialog__header {
		display: none;
	}

	/deep/ .el-dialog__body {
		background: #fbf7f5;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.top-col {
		width: 100%;
		height: 60px;
		padding: 0 20px;
		display: flex;
		align-items: center;
		border: 1px solid #e5e5e5;
		margin-top: 16px;
		border-radius: 12px;
		color: #222222;
		font-size: 16px;

		b {
			margin-left: 4px;
		}
	}

	.word-panel {
		border-radius: 12px;
		min-height: 400px;
		padding: 32px;
		margin-top: 20px;
		position: relative;
		border: 1px solid #e5e5e5;
		display: flex;
		flex-direction: column;
		align-items: center;

		.master {
			color: #222;
			font-size: 34px;
			font-weight: bold;
			position: relative;
			display: flex;
			word-break: break-all;

			span {
				position: relative;
				z-index: 8;
			}

			.box-showdow {
				position: absolute;
				width: 100%;
				height: 10px;
				background: #fdf0cf;
				left: 3px;
				bottom: 8px;
				border-radius: 4px;
			}
		}

		.linsten-block {
			margin-top: 7px;
			display: flex;
			align-items: center;

			span {
				font-size: 16px;
				margin-left: 12px;
				color: #666;
			}

			.linsten-btn {
				border-radius: 20px;
				border: 1px solid #e0e0e0;
				background: #fff;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				height: 24px;
				width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				&:hover {
					transform: scale(1.05);
				}

				img {
					width: 16px;
					height: 16px;
				}
			}
		}

		.desc-item {
			display: flex;
			margin-top: 16px;
			line-height: 18px;

			span:first-child {
				font-size: 16px;
				color: #999999;
			}

			span:last-child {
				font-size: 14px;
				color: #222222;
				margin-left: 12px;
			}
		}

		.sentence {
			width: 263px;
			margin-top: 20px;
		}

		.look-desc {
			margin-top: 12px;
			font-size: 16px;
			font-weight: bold;
			color: #0075ff;
			width: 263px;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.mean {
			font-size: 14px;
			margin-top: 12px;
			width: 263px;
			color: #666;
		}

		.word-img {
			width: 263px;
			height: 136px;
			border-radius: 12px;
			margin-top: 20px;
		}

		.mid-line {
			border-bottom: dashed 1px #dbdbdb;
			width: 263px;
			margin: 20px 0;
		}

		.s-tip {
			width: 263px;
			font-size: 14px;
			font-weight: bold;
			color: #5c3704;
		}

		.select-list {
			display: flex;
			flex-direction: column;
			width: 263px;
			margin-top: 16px;

			.select-item {
				display: flex;
				align-items: center;
				margin-bottom: 12px;
				height: 55px;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				background: #ffffff;
				border-radius: 8px;
				padding: 0 20px;
				position: relative;
				border: solid 1px #e5e5e5;
				font-weight: bold;
				cursor: pointer;

				&:hover {
					border: solid 1px #aaa;
				}
			}

			.error {
				background: #f85b59 !important;
				color: #fff !important;
			}

			.right {
				background: #51d9a5 !important;
				color: #fff !important;
			}

			.select-icon {
				height: 28px;
				position: absolute;
				right: 0;
				top: -31px;
				object-fit: contain;
			}
		}

		.two-btn {
			display: flex;
			align-items: center;
			margin-top: 40px;

			div {
				box-shadow: 0px 2px 6px 0px #0000001f;
				width: 113px;
				height: 40px;
				border-radius: 40px;
				color: #5c3704;
				cursor: pointer;
				font-size: 14px;
				font-weight: bold;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.3s all;

				&:hover {
					opacity: 0.8;
				}

				&:last-child {
					margin-left: 12px;
					background: #51d9a5;
					color: #fff;
				}
			}
		}
	}

	.btn-group {
		margin: 20px 0 40px;

		img {
			cursor: pointer;
			transition: all 0.3s;

			&:hover {
				opacity: 0.8;
				transform: scale(1.05);
			}
		}
	}
</style>